import { useEffect } from "react";
import TagManager from 'react-gtm-module'


const useGtmTracker = () => {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GOOGLE_TAG_Manager_ID
    }


    useEffect(() => {
        if (process.env.REACT_APP_ENV_NAME === "release") {
            TagManager.initialize(tagManagerArgs);
        }
    }, []);
}
export default useGtmTracker;