import { useEffect, useState, useRef } from 'react'
import {
    Grid,
    Button,
} from "@mui/material"
import styles from './index.module.scss';
import feature_1 from "../../assets/feature_1.svg";
import feature_2 from "../../assets/feature_2.svg";
import feature_3 from "../../assets/feature_3.svg";
import feature_4 from "../../assets/feature_4.svg";
import icSvg from "../../assets/ic_chip@3x.png"
import useIntersectionObserver from "../../hooks/IntersectionObserver.js";

const IcSvg = (props) =>{
  const {
    screenWidth,
    leftOneisVisible,
    leftTwoisVisible,
    rightOneisVisible,
    rightTwoisVisible,
    onLeftOneBothTrue,
    onLeftTwoBothTrue,
    onRightOneBothTrue,
    onRightTwoBothTrue,
  } = props;
  const [leftOnePathVisible, setLeftOnePathVisible] = useState(false);
  const [leftOneEllipseVisible, setLeftOneEllipseVisible] = useState(false);
  const [leftTwoPathVisible, setLeftTwoPathVisible] = useState(false);
  const [leftTwoEllipseVisible, setLeftTwoEllipseVisible] = useState(false);
  const [rightOnePathVisible, setRightOnePathVisible] = useState(false);
  const [rightOneEllipseVisible, setRightOneEllipseVisible] = useState(false);
  const [rightTwoPathVisible, setRightTwoPathVisible] = useState(false);
  const [rightTwoEllipseVisible, setRightTwoEllipseVisible] = useState(false);

  // 線條是否畫完
  const pathAnimation = (isVisible, pathVisible, both)=>{
    if (isVisible) {
      const timer = setTimeout(() => {
        pathVisible(true);
      }, 100);

      return () => clearTimeout(timer);
    }
  }

  // 圈圈是否畫完
  const ellipseAnimation = (pathVisible, ellipseVisible) =>{
    if (pathVisible) {
      const timer = setTimeout(() => {
        ellipseVisible(true);
      }, 350);

      return () => clearTimeout(timer);
    }
  }

  // 線條與圈圈兩者是否都畫完成
  const bothTrue = (pathVisible, ellipseVisible, isBoth) =>{
    if (pathVisible && ellipseVisible) {
      const timer = setTimeout(() => {
        isBoth(true);
      }, 1);
      return () => clearTimeout(timer);
    }
  }

  useEffect(() => {
    pathAnimation(leftOneisVisible, setLeftOnePathVisible, setLeftOneEllipseVisible);
  }, [leftOneisVisible]);

  useEffect(() => {
    ellipseAnimation(leftOnePathVisible, setLeftOneEllipseVisible);
  }, [leftOnePathVisible]);

  useEffect(() => {
    bothTrue(leftOnePathVisible, leftOneEllipseVisible, onLeftOneBothTrue);
  }, [leftOnePathVisible, leftOneEllipseVisible]);

  useEffect(() => {
    pathAnimation(leftTwoisVisible, setLeftTwoPathVisible, setLeftTwoEllipseVisible);
  }, [leftTwoisVisible]);

  useEffect(() => {
    ellipseAnimation(leftTwoPathVisible, setLeftTwoEllipseVisible);
  }, [leftTwoPathVisible]);

  useEffect(() => {
    bothTrue(leftTwoPathVisible, leftTwoEllipseVisible, onLeftTwoBothTrue);
  }, [leftTwoPathVisible, leftTwoEllipseVisible]);

  useEffect(() => {
    pathAnimation(rightOneisVisible, setRightOnePathVisible, setRightOneEllipseVisible);
  }, [rightOneisVisible]);

  useEffect(() => {
    ellipseAnimation(rightOneisVisible, setRightOneEllipseVisible);
  }, [rightOnePathVisible]);

  useEffect(() => {
    bothTrue(rightOnePathVisible, rightOneEllipseVisible, onRightOneBothTrue);
  }, [rightOnePathVisible, rightOneEllipseVisible]);

  useEffect(() => {
    pathAnimation(rightTwoisVisible, setRightTwoPathVisible, setRightTwoEllipseVisible);
  }, [rightTwoisVisible]);

  useEffect(() => {
    ellipseAnimation(rightTwoisVisible, setRightTwoEllipseVisible);
  }, [rightTwoPathVisible]);

  useEffect(() => {
    bothTrue(rightTwoPathVisible, rightTwoEllipseVisible, onRightTwoBothTrue);
  }, [rightTwoPathVisible, rightTwoEllipseVisible]);

  
  return(
      <svg style={{textAlign:"center"}} className={styles.svgBox} width="160" height={screenWidth > 900 ? 1350 : 180} viewBox={`0 0 197 ${screenWidth > 900 ? 1800 : 180}`} fill="none" >
        <image
          x="10"
          y="10"
          width="180"
          height="180"
          href={icSvg}
        />

        {/* 樹狀線條 */}
        {screenWidth > 900 ? 
          <>
            <path d="M99 173V9462.5" stroke="url(#paint0_linear_13490_13584)" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
              className={`${styles.feature} ${leftOnePathVisible ? styles.animated_path : ''} ${styles.fade} ${leftOnePathVisible ? styles.visibleaa : styles.hidden}`}
              d="M98.5 265 L98.5 337 C98.5 385 91 409 -5.5 409"
              stroke="#4CAAFF"
              strokeWidth="4"
              strokeDasharray="500"
              strokeDashoffset={leftOnePathVisible ? '0' : '500'}
              fill="none"
            />
          <ellipse
            className={`${styles.feature} ${styles.delayed_animation} ${styles.fade} ${leftOneEllipseVisible ? styles.visibleaa : styles.hidden}`}
            cx="271"
            cy="0"
            rx="6"
            ry="6"
            transform="matrix(4.37114e-08 1 1 -4.37114e-08 0 138)"
            fill="#4CAAFF"
          />
          <path
            className={`${styles.feature} ${leftTwoPathVisible ? styles.animated_path : ''} ${styles.fade} ${leftTwoPathVisible ? styles.visibleaa : styles.hidden}`}
            d="M98.5 960 L98.5 932 C98.5 980 91 1004 -5.5 1004"
            stroke="#5FC6EA"
            strokeWidth="4"
            strokeDasharray="500"
            strokeDashoffset={leftTwoPathVisible ? '0' : '500'}
            fill="none"
          />
          <ellipse
            className={`${styles.feature} ${leftTwoEllipseVisible ? styles.delayed_animation : ''} ${styles.fade} ${leftTwoEllipseVisible ? styles.visibleaa : styles.hidden}`}
            cx="866"
            cy="0"
            rx="6"
            ry="6"
            transform="matrix(4.37114e-08 1 1 -4.37114e-08 0 138)"
            fill="#5EC5EA"
          />
          <path
            className={`${styles.feature} ${rightOnePathVisible ? styles.animated_path : ''} ${styles.fade} ${rightOnePathVisible ? styles.visibleaa : styles.hidden}`}
            d="M98.5 550 L98.5 622 C98.5 670 115.5 694 201.5 694"
            stroke="#55B9F4"
            strokeWidth="4"
            strokeDasharray="500"
            strokeDashoffset={rightOnePathVisible ? '0' : '500'}
            fill="none"
          />
          <circle
            className={`${styles.feature} ${rightOneEllipseVisible ? styles.delayed_animation : ''} ${styles.fade} ${rightOneEllipseVisible ? styles.visibleaa : styles.hidden}`}
            cx="655"
            cy="50"
            r="6"
            transform="rotate(90 105 144)"
            fill="#56BAF4"
          />
          <path
            className={`${styles.feature} ${rightTwoPathVisible ? styles.animated_path : ''} ${styles.fade} ${rightTwoPathVisible ? styles.visibleaa : styles.hidden}`}
            d="M98.5 1155 L98.5 1227 C98.5 1275 115.5 1299 201.5 1299"
            stroke="#66D2E1"
            strokeWidth="4"
            strokeDasharray="500"
            strokeDashoffset={rightTwoPathVisible ? '0' : '500'}
            fill="none"
          />
          <circle
            className={`${styles.feature} ${rightTwoEllipseVisible ? styles.delayed_animation : ''} ${styles.fade} ${rightTwoEllipseVisible ? styles.visibleaa : styles.hidden}`}
            cx="1260"
            cy="50"
            r="6"
            transform="rotate(90 105 144)"
            fill="#66D2E1"
          />
            
            <circle className={styles.feature} cx="434" cy="1453" r="12" transform="rotate(90 99 1453)" fill="#71E3D4"/>
            <defs>
              <linearGradient className={styles.feature} id="paint0_linear_13490_13584" x1="99.3745" y1="1462.5" x2="99.3745" y2="173.496" gradientUnits="userSpaceOnUse">
              <stop stopColor="#71E3D3"/>
              <stop offset="1" stopColor="#4BA9FF"/>
              </linearGradient>
            </defs>
          </>
        :null}
      </svg>
  )
}

const AboutLayer = () => {
  const [screenWidth, setScreenWidth] = useState(document.body.clientWidth);
  const [leftIsVisible, setLeftIsVisible] = useState(false);
  const [rightisVisible, setrightIsVisible] = useState(false);

  const [leftOneImg, setLeftOneImg] = useState(false);
  const [leftTwoImg, setLeftTwoImg] = useState(false);
  const [rightOneImg, setRightOneImg] = useState(false);
  const [rightTwoImg, setRightTwoImg] = useState(false);
  const [leftOneisVisible, setleftOneIsVisible] = useState(false);
  const [leftTwoisVisible, setleftTwoIsVisible] = useState(false);
  const [rightOneisVisible, setrightOneIsVisible] = useState(false);
  const [rightTwoisVisible, setrightTwoIsVisible] = useState(false);
  const [footTextIsVisible, setfootTextIsVisible] = useState(false);

  // 動畫跑過一次後，不再繼續重跑。
  const [leftIsVisibleOnce, setLeftIsVisibleOnce] = useState(false);
  const [rightIsVisibleOnce, setRightIsVisibleOnce] = useState(false);
  const [footTextIsVisibleOnce, setFootTextIsVisibleOnce] = useState(false);

  const pRefs = useRef([]);
  const leftRef = useRef(null);
  const leftOneRef = useRef(null);
  const leftTwoRef = useRef(null);
  const rightRef = useRef(null);
  const rightOneRef = useRef(null);
  const rightTwoRef = useRef(null);
  const footTextRef = useRef(null);

  // 追蹤該元素位置
  useIntersectionObserver(leftOneRef, setleftOneIsVisible, screenWidth);
  useIntersectionObserver(leftTwoRef, setleftTwoIsVisible, screenWidth);
  useIntersectionObserver(rightOneRef, setrightOneIsVisible, screenWidth);
  useIntersectionObserver(rightTwoRef, setrightTwoIsVisible, screenWidth);

  useIntersectionObserver(leftRef, (isVisible) => {
    if (isVisible) {
      setLeftIsVisible(true);
      setLeftIsVisibleOnce(true);
    } else if (!leftIsVisibleOnce) {
      setLeftIsVisible(false);
    }
  }, screenWidth);

  useIntersectionObserver(rightRef, (isVisible) => {
    if (isVisible) {
      setrightIsVisible(true);
      setRightIsVisibleOnce(true);
    } else if (!rightIsVisibleOnce) {
      setrightIsVisible(false);
    }
  }, screenWidth);

  useIntersectionObserver(footTextRef, (isVisible) => {
    if (isVisible) {
      setfootTextIsVisible(true);
      setFootTextIsVisibleOnce(true);
    } else if (!footTextIsVisibleOnce) {
      setfootTextIsVisible(false);
    }
  }, screenWidth);
  

  const handleLeftOneBothTrue = (isBothTrue) => {
    setLeftOneImg(isBothTrue);
  };

  const handleLeftTwoBothTrue = (isBothTrue) => {
    setLeftTwoImg(isBothTrue);
  };

  const handleRightOneBothTrue = (isBothTrue) => {
    setRightOneImg(isBothTrue);
  };

  const handleRightTwoBothTrue = (isBothTrue) => {
    setRightTwoImg(isBothTrue);
  };

// <p> 浮現動畫
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          } else {
            entry.target.classList.remove(styles.animate);
          }
        });
      },
      { threshold: 0.5 }
    );

    pRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      pRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);
  
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <div className={styles.container} >
        <div className={styles.wrap}>
          <Grid className={styles.title}><h1>怎麼辦到的？</h1></Grid>
          <Grid className={styles.subTitle}>
            <p ref={(ref) => (pRefs.current[0] = ref)} >
             <span>我們將學習內容轉化成可被運算的智慧化資料。</span> 
            </p>
            <p ref={(ref) => (pRefs.current[1] = ref)} >
             <span>並結合獨門的AI演算技術。</span> 
            </p>
            <p ref={(ref) => (pRefs.current[2] = ref)} >
             <span>透過敏捷學習的方式幫助您學得又快又好。</span> 
            </p>
          </Grid>
          <Grid container className={styles.contentBox}>
          {screenWidth > 650 ? 
          <>
            <Grid item xs={screenWidth > 900 ? 5 : 4} className={styles.LeftContent}> 
            <div ref={leftRef} className={`${leftIsVisible ? styles.fadein : styles.visible}`}>
                <h1 style={{ color: "#57BBF2" }}>智慧化資料</h1>
                <p>我們透過NLP及AI技術標註智慧標籤，讓學習內容更有價值。</p>
            </div>
              {screenWidth > 900 ? 
                <>
                  <div ref={leftOneRef} className={`${leftOneImg ? styles.fadein : styles.visible}`}>
                    <h1 style={{color:"#4CAAFF", marginTop:"195px"}}>精準前測，分析落點。</h1>
                    <span>透過 AI 配題的前測，廣納不同知識點底下的重要題目，讓您用最短的時間確認當前的能力狀態，並且設立目標，逐步前進。</span>
                    <div><img src={feature_1} className={styles.img_feature_1}/></div>
                  </div>
                  <div ref={leftTwoRef} className={`${leftTwoImg ? styles.fadein : styles.visible}`}>
                    <h1 style={{color:"#5EC5EA", marginTop:"103px"}}>持續進步，掌握知識。</h1>
                    <span>首創的學習內容知識點，完整的圖表與清晰的呈現，讓您不再需要盲目的刷題，直搗弱點訓練核心，持續再進化。</span>
                    <div><img src={feature_3} className={styles.img_feature_3}/></div>
                  </div>
                </> : null}
            </Grid>
            <Grid item xs={screenWidth > 900 ? 2 : 4} style={{textAlign:"center"}}>
              <IcSvg 
              screenWidth={screenWidth}
              leftOneisVisible={leftOneisVisible}
              leftTwoisVisible={leftTwoisVisible}
              rightOneisVisible={rightOneisVisible}
              rightTwoisVisible={rightTwoisVisible}
              onLeftOneBothTrue={handleLeftOneBothTrue}
              onLeftTwoBothTrue={handleLeftTwoBothTrue}
              onRightOneBothTrue={handleRightOneBothTrue}
              onRightTwoBothTrue={handleRightTwoBothTrue}
              />
            </Grid>
            <Grid item xs={screenWidth > 900 ? 5 : 4} className={styles.rightContent}>
              <div ref={rightRef} className={`${rightisVisible ? styles.fadein : styles.visible}`}>
                <h1 style={{color:"#6AD7DD"}}>智慧化平台</h1>
                <span>透過個人化智慧推題機制，以及知識點握度的運算，讓學習過程變得更聰明簡單。</span>
              </div>
              {screenWidth > 900 ? 
                <>
                  <div ref={rightOneRef} className={`${rightOneImg ? styles.fadein : styles.visible}`}>
                    <h1 style={{color:"#56BAF4", marginTop:"390px"}}>AI 出擊，智慧推題。</h1>
                    <span>時間就該花在重要的地方！從不間斷的雲端運算，分析每一次的答題結果並推送個人化題目，幫助您對得更多，錯得更少！</span>
                    <div><img src={feature_2} className={styles.img_feature_2}/></div>
                  </div>
                  <div ref={rightTwoRef} className={`${rightTwoImg ? styles.fadein : styles.visible}`}>
                    <h1 style={{color:"#66D2E1", marginTop:"118px"}}>個人歷程，完整記錄。</h1>
                    <span>您的每一次進步都值得慶祝，所有的歷程軌跡會由 AI 詳實記錄。透過同儕間的對照與比較，延展您的學習視野。</span>
                    <div><img src={feature_4} className={styles.img_feature_4}/></div>
                  </div>
                </> : null}
            </Grid>
          </>
          :
          <>
            <Grid item xs={12} style={{textAlign:"center"}}>
              <IcSvg 
                screenWidth={screenWidth}
                leftOneisVisible={leftOneisVisible}
                leftTwoisVisible={leftTwoisVisible}
                rightOneisVisible={rightOneisVisible}
                rightTwoisVisible={rightTwoisVisible}
                onLeftOneBothTrue={handleLeftOneBothTrue}
                onLeftTwoBothTrue={handleLeftTwoBothTrue}
                onRightOneBothTrue={handleRightOneBothTrue}
                onRightTwoBothTrue={handleRightTwoBothTrue} 
                />
            </Grid>
            <Grid item xs={6} className={styles.LeftContent}> 
              <div ref={leftRef} className={`${leftIsVisible ? styles.fadein : styles.visible}`}>
                <h1 style={{color:"#57BBF2",textAlign:"center"}}>智慧化資料</h1>
                <p>我們透過NLP及AI技術標註智慧標籤，讓學習內容更有價值。</p>
              </div>
              {screenWidth > 900 ? 
                <>
                  <div ref={leftOneRef} className={`${leftOneisVisible ? styles.fadein : styles.visible}`}>
                    <h1 style={{color:"#4CAAFF", marginTop:"200px"}}>精準前測，分析落點。</h1>
                    <span>透過 AI 配題的前測，廣納不同知識點底下的重要題目，讓您用最短的時間確認當前的能力狀態，並且設立目標，逐步前進。</span>
                    <div><img src={feature_1} className={styles.img_feature_1}/></div>
                  </div>
                  <div ref={leftTwoRef} className={`${leftTwoisVisible ? styles.fadein : styles.visible}`}>
                    <h1 style={{color:"#5EC5EA", marginTop:"120px"}}>持續進步，掌握知識。</h1>
                    <span>首創的學習內容知識點，完整的圖表與清晰的呈現，讓您不再需要盲目的刷題，直搗弱點訓練核心，持續再進化。</span>
                    <div><img src={feature_3} className={styles.img_feature_3}/></div>
                  </div>
                </> : null}
            </Grid>
            <Grid item xs={6} className={styles.rightContent}>
              <div ref={rightRef} className={`${rightisVisible ? styles.fadein : styles.visible}`}>
                <h1 style={{color:"#6AD7DD"}}>智慧化平台</h1>
                <span>透過個人化智慧推題機制，以及知識點握度的運算，讓學習過程變得更聰明簡單。</span>
              </div>
              {screenWidth > 900 ? 
                <>
                  <div ref={rightOneRef} className={`${rightOneisVisible ? styles.fadein : styles.visible}`}>
                    <h1 style={{color:"#56BAF4", marginTop:"395px"}}>AI 出擊，智慧推題。</h1>
                    <span>時間就該花在重要的地方！從不間斷的雲端運算，分析每一次的答題結果並推送個人化題目，幫助您對得更多，錯得更少！</span>
                    <div><img src={feature_2} className={styles.img_feature_2}/></div>
                  </div>
                  <div>
                    <h1 ref={leftTwoRef} className={`${leftTwoisVisible ? styles.fadein : styles.visible}`} style={{color:"#66D2E1", marginTop:"135px"}}>個人歷程，完整記錄。</h1>
                    <span>您的每一次進步都值得慶祝，所有的歷程軌跡會由 AI 詳實記錄。透過同儕間的對照與比較，延展您的學習視野。</span>
                    <div><img src={feature_4} className={styles.img_feature_4}/></div>
                  </div>
                </> : null}
            </Grid>
          </>}
          </Grid>
          {screenWidth < 900 ? 
            <>
              <Grid className={styles.rwdContent}>
                <div style={{marginTop:"30px"}} ref={leftOneRef} className={`${leftOneisVisible ? styles.fadein : styles.visible}`}>
                  <h1 style={{color:"#4CAAFF", fontSize:screenWidth < 600 ? "30px":"38px"}}>精準前測，分析落點。</h1>
                  <span style={{textAlign:"center"}}>透過 AI 配題的前測，廣納不同知識點底下的重要題目，讓您用最短的時間確認當前的能力狀態，並且設立目標，逐步前進。</span>
                  <div className={styles.contentImgBox}><img src={feature_1} className={styles.img_feature_1}/></div>
                </div>
                <div style={{marginTop:"30px"}} ref={rightOneRef} className={`${rightOneisVisible ? styles.fadein : styles.visible}`}>
                  <h1 style={{color:"#56BAF4", fontSize:screenWidth < 600 ? "30px":"38px"}}>AI 出擊，智慧推題。</h1>
                  <span style={{textAlign:"center"}}>時間就該花在重要的地方！從不間斷的雲端運算，分析每一次的答題結果並推送個人化題目，幫助您對得更多，錯得更少！</span>
                  <div className={styles.contentImgBox} ><img src={feature_2} className={styles.img_feature_2}/></div>
                </div>
                <div style={{marginTop:"30px"}} ref={leftTwoRef} className={`${leftTwoisVisible ? styles.fadein : styles.visible}`}>
                  <h1 style={{color:"#5EC5EA", fontSize:screenWidth < 600 ? "30px":"38px"}}>持續進步，掌握知識。</h1>
                  <span >首創的學習內容知識點，完整的圖表與清晰的呈現，讓您不再需要盲目的刷題，直搗弱點訓練核心，持續再進化。</span>
                  <div className={styles.contentImgBox}><img src={feature_3} className={styles.img_feature_3}/></div>
                </div>
                <div style={{marginTop:"30px"}} ref={rightTwoRef} className={`${rightTwoisVisible ? styles.fadein : styles.visible}`}>
                  <h1 style={{color:"#66D2E1", fontSize:screenWidth < 600 ? "30px":"38px"}}>個人歷程，完整記錄。</h1>
                  <span style={{textAlign:"center"}}>您的每一次進步都值得慶祝，所有的歷程軌跡會由 AI 詳實記錄。透過同儕間的對照與比較，延展您的學習視野。</span>
                  <div className={styles.contentImgBox}><img src={feature_4} className={styles.img_feature_4}/></div>
                </div>
              </Grid> 
            </>
          : null}
          <Grid ref={footTextRef} className={`${styles.footText} ${footTextIsVisible ? styles.fadein : styles.visible}`}><span>對所有人提供高效率、節省時間且最佳化的解決方案。</span></Grid>
        </div>
    </div>
  )
}

export default AboutLayer;
