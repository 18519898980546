import { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import useScreen from "../../hooks/screen";
import HomeBannerImg_360 from "../../assets/HomeBannerImg_360.svg";
import HomeBannerImg_768 from "../../assets/HomeBannerImg_768.svg";
import HomeBannerImg_1440 from "../../assets/HomeBannerImg_1440.svg";
import HomeBannerImg_1920 from "../../assets/HomeBannerImg_1920.svg";
import pic_toC_inside_page_banner_360 from "../../assets/pic_toC_inside_page_banner_360.svg";
import pic_toC_inside_page_banner_768 from "../../assets/pic_toC_inside_page_banner_768.svg";
import pic_toC_inside_page_banner_1440 from "../../assets/pic_toC_inside_page_banner_1440.svg";
import pic_toC_inside_page_banner_1920 from "../../assets/pic_toC_inside_page_banner_1920.svg";
import logo_tag_easy_white_ver from "../../assets/logo_tag_easy_white_ver.svg";



const BannerLayer = (props) => {
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    setImgDimensions({ width: naturalWidth, height: naturalHeight });
    setIsImageLoaded(true);
  };

  const aspectRatio = (imgDimensions.height / imgDimensions.width) * 100 || 56.25; 

  const navigate = useNavigate();
  const screenWidth = useScreen();
  // 圖片高度
  const [imgHeight, setImgHeight] = useState();
  // 當前輪播位置
  const [currentIndex, setCurrentIndex] = useState(0);
  let totalIndex = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex === totalIndex) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  const handlePage = (index) => {
    setCurrentIndex(index);
  }

  // 處理當前圖片
  const handleCurrentImg = (type) => {
    if (type === "ToC") {
      if (screenWidth > 1440) {
        return HomeBannerImg_1920;
      } else if (screenWidth > 768) {
        return HomeBannerImg_1440;
      }
      else if (screenWidth > 360) {
        return HomeBannerImg_768;
      } else {
        return HomeBannerImg_360;
      }
    }
    if (type === "ToC_inner") {
      if (screenWidth > 1440) {
        return pic_toC_inside_page_banner_1920;
      } else if (screenWidth > 768) {
        return pic_toC_inside_page_banner_1440;
      }
      else if (screenWidth > 600) {
        return pic_toC_inside_page_banner_768;
      } else {
        return pic_toC_inside_page_banner_360;
      }
    }
  }

  const handleLogin = () => {
    if (process.env.REACT_APP_ENV_NAME === "dev") {
      window.open('https://toeic-dev.tageasy.com.tw/', '_blank');
    } else {
      window.open('https://toeic.tageasy.com.tw/', '_blank');
    }
  }

  // // 處理當前圖片高度
  // const handleCurrentImgHeight = () => {
  //   if (screenWidth > 800) {
  //     setImgHeight("720px");
  //   } else {
  //     setImgHeight("480px");
  //   }
  // }

  // useEffect(() => {
  //   handleCurrentImgHeight();
  // }, [screenWidth]);

  const handleLink = (type) => {
    if (type === "ToC") {
      navigate("/toeic");
    } else if (type === "ToS") {
      console.log("導向ToS");
    } else if (type === "ToB") {
      console.log("導向ToB");
    }
  }

  // 紀錄滑動位置
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);

  // 滑動距離超過此值才有效
  const swipeThreshold = 50;

  // 螢幕小於 600px 時，啟動滑動模式 
  const isMobile = useMediaQuery('(max-width:768px)');

  // 滑動事件開始
  const onTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  }

  // 滑動事件結束
  const onTouchEnd = (e) => {
    touchEndX.current = e.changedTouches[0].clientX;
    handleSwipe();
  }

  // 處理滑動邏輯
  const handleSwipe = () => {
    const distance = touchStartX.current - touchEndX.current;

    // 向左滑動
    if (distance > swipeThreshold && currentIndex < totalIndex) {
      setCurrentIndex(prevIndex => prevIndex + 1);
    }

    // 向右滑動
    if (distance < -swipeThreshold && currentIndex > 0) {
      setCurrentIndex(prevIndex => prevIndex - 1);
    }
  }

  const handleHomePage = () =>{
    navigate("/");
  }


  return (
    <div
      className={styles.container}
      onTouchStart={isMobile ? onTouchStart : null}
      onTouchEnd={isMobile ? onTouchEnd : null}
      style={{ position: 'relative', width: '100%', paddingBottom: `${aspectRatio}%`, zIndex: '1' }}
    >
      {props.url === "Home" &&
        <>
          {/* {screenWidth > 768 &&
            <div className={styles.indexButtonBox} >
              {Array.from({ length: totalIndex + 1 }).map((_, index) => (
                <svg
                  key={index}
                  width={currentIndex === index ? '16' : '8'}
                  height={currentIndex === index ? '8' : '8'}
                  viewBox={currentIndex === index ? '0 0 16 8' : '0 0 8 8'}
                  fill="none"
                  style={{ margin: '0px 5px', cursor: "pointer" }}
                  onClick={() => handlePage(index)}
                >
                  <rect
                    width={currentIndex === index ? '16' : '8'}
                    height={currentIndex === index ? '8' : '8'}
                    rx={currentIndex === index ? '4' : '4'}
                    fill={currentIndex === index ? '#FFFFFF' : '#D9D9D9'}
                  />
                </svg>
              ))}
            </div>
          } */}
          {/* <div className={styles.rightButtonBox} >
            <div onClick={() => handleLink("ToC")}>個人學習</div>
            <div onClick={() => handleLink("ToS")}>校園方案</div>
            <div onClick={() => handleLink("ToB")}>企業合作</div>
          </div> */}
          {currentIndex === 0 && (
            <div style={{ cursor: "pointer" }} onClick={() => handleLink("ToC")}>
              <img src={handleCurrentImg("ToC")} alt="Banner"  onLoad={handleImageLoad} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}/>
            </div>
          )}
          {/* {currentIndex === 1 && (
            <div style={{ cursor: "pointer" }}>
              <img src={handleCurrentImg("ToC")} alt="Banner" />
            </div>
          )}
          {currentIndex === 2 && (
            <div style={{ cursor: "pointer" }}>
              <img src={handleCurrentImg("ToC")} alt="Banner" />
            </div>
          )} */}
        </>
      }
      {props.url === "ToC" &&
        <>
          {
            isImageLoaded &&
            <>
              <div onClick={() => handleHomePage()} className={styles.leftButtonBox}>
                <img src={logo_tag_easy_white_ver}/>
              </div>
              {/* <div className={styles.rightButtonBox} >
                <div onClick={() => handleLink("ToC")} style={{ fontWeight: "600" }}>個人學習</div>
                <div onClick={() => handleLink("ToS")}>校園方案</div>
                <div onClick={() => handleLink("ToB")}>企業合作</div>
              </div> */}
              <div className={screenWidth > 600 ? styles.TrialButtonBox : "" }>
                <div onClick={() => handleLogin()} >免費七天試用</div>
              </div>
            </>
          }
          <img src={handleCurrentImg("ToC_inner")} alt="Banner"  onLoad={handleImageLoad} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}/>
        </>
      }
    </div>
  );
}

export default BannerLayer;
