import { Routes, Route } from "react-router-dom";
import HomePage from './components/HomePage/index';
import ToCPage from './components/ToCPage/index';
import useGtmTracker from './hooks/gtmTracker';


function App() {
  useGtmTracker();
  return (
    <div>
      <Routes>
        <Route path="" element={<HomePage />} />
        <Route path="*" element={<HomePage />} />
        <Route path="/toeic" element={<ToCPage />} />
      </Routes>
    </div>

  )
}

export default App
