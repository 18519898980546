import { useEffect, useState, useRef } from 'react'
import {
  Grid,
  Button,
} from "@mui/material"
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import logo_tag_easy_A from "../../assets/logo_tag_easy_A.svg";



const TitleLayer = () => {
  const navigate = useNavigate();

  const BackgroundColor = () => {
    const containerRef = useRef(null);
    const [columns, setColumns] = useState([]);

    // 隨螢幕寬度增加列數
    useEffect(() => {
      const handleResize = () => {
        const containerWidth = containerRef.current.clientWidth;
        const numColumns = Math.ceil(containerWidth / 15);
        const newColumns = Array.from({ length: numColumns }, (_, index) => (
          <g key={index} transform={`translate(${index * 15}, 0)`}>
            <circle cx="18" cy="17" r="1" fill="#4BAAFF" />
            <circle cx="18" cy="35" r="1" fill="#4BAAFF" />
            <circle cx="18" cy="53" r="1" fill="#4BAAFF" />
            <circle cx="18" cy="71" r="1" fill="#4BAAFF" />
            <circle cx="18" cy="89" r="1" fill="#50B2F9" />
            <circle cx="18" cy="107" r="1" fill="#50B2F9" />
            <circle cx="18" cy="125" r="1" fill="#50B2F9" />
            <circle cx="18" cy="143" r="1" fill="#50B2F9" />
            <circle cx="18" cy="161" r="1" fill="#54B8F4" />
            <circle cx="18" cy="179" r="1" fill="#54B8F4" />
            <circle cx="18" cy="197" r="1" fill="#54B8F4" />
            <circle cx="18" cy="215" r="1" fill="#54B8F4" />
            <circle cx="18" cy="233" r="1" fill="#59BFEE" />
            <circle cx="18" cy="251" r="1" fill="#59BFEE" />
            <circle cx="18" cy="269" r="1" fill="#59BFEE" />
            <circle cx="18" cy="287" r="1" fill="#59BFEE" />
            <circle cx="18" cy="305" r="1" fill="#5FC8E7" />
            <circle cx="18" cy="323" r="1" fill="#5FC8E7" />
            <circle cx="18" cy="341" r="1" fill="#5FC8E7" />
            <circle cx="18" cy="359" r="1" fill="#5FC8E7" />
            <circle cx="18" cy="377" r="1" fill="#63CEE2" />
            <circle cx="18" cy="395" r="1" fill="#63CEE2" />
            <circle cx="18" cy="413" r="1" fill="#63CEE2" />
            <circle cx="18" cy="431" r="1" fill="#63CEE2" />
            <circle cx="18" cy="449" r="1" fill="#69D7DC" />
            <circle cx="18" cy="467" r="1" fill="#69D7DC" />
            <circle cx="18" cy="485" r="1" fill="#69D7DC" />
            <circle cx="18" cy="503" r="1" fill="#69D7DC" />
            <circle cx="18" cy="521" r="1" fill="#6EDFD5" />
            <circle cx="18" cy="539" r="1" fill="#6EDFD5" />
            <circle cx="18" cy="557" r="1" fill="#6EDFD5" />
            <circle cx="18" cy="575" r="1" fill="#6EDFD5" />
          </g>
        ));
        setColumns(newColumns);
      };

      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
      <div ref={containerRef} style={{ position: 'absolute', top: -10, left: -10, right: 0, bottom: 0, zIndex: "-100000" }}>
        <svg width="100%" height="100%" fill="none">
          {columns}
        </svg>
      </div>
    );
  };

  const handleConsulBtn = () => {
    window.location.href = 'https://www.colerate.com.tw/contact'
  }

  const handleLoginBtn = () => {
    window.scrollTo(0, 0);
    navigate("/toeic");
    // if(process.env.REACT_APP_ENV_NAME === "dev"){
    //   window.location.href = 'https://testprep-dev.tageasy.com.tw/auth/login'
    // }else{
    //   window.location.href = 'https://testprep.tageasy.com.tw/auth/login'
    // }
  }

  return (
    <div className={styles.container}>

      <div className={styles.wrap} style={{ position: 'relative', zIndex: '1' }}>
        <BackgroundColor />
        <Grid><img src={logo_tag_easy_A} className={styles.img_logo_tag_easy_A} /></Grid>
        <Grid><h1>最懂你的 AI 神隊友</h1></Grid>
        <Grid><span>提供個人化的 AI 學習體驗，省時且提高效率與學習成果。</span></Grid>
        <Grid>
          <Button className={styles.consultBtn} onClick={() => handleConsulBtn()} >諮詢細節</Button>
          <Button className={styles.loginBtn} onClick={() => handleLoginBtn()} >加入我們</Button>
        </Grid>
      </div>
    </div>
  )
}

export default TitleLayer;
