import { useEffect, useState, useRef } from 'react'
import {
  Grid,
  Button,
  Box
} from "@mui/material"
import styles from './index.module.scss';
import smart_push from "../../assets/smart_push.svg";
import to_do_list from "../../assets/to_do_list.svg";
import { useNavigate } from 'react-router-dom';

// 觀察元素是否被接觸
const useIntersectionObserver = (initialCount, ref, setRef) => {
  useEffect(() => {
    if (initialCount <= 1) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setRef(true);
            }
          });
        },
        { threshold: 0.2 }
      );

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    } else {
      // 如果 initialCount 大於或等於 1,則停止 IntersectionObserver
      if (ref.current) {
        const observer = new IntersectionObserver((entries) => { });
        observer.unobserve(ref.current);
      }
    }
  }, [initialCount, ref, setRef]);
};

const GreenLineAnimation = ({ animationStart, curentScreenWidth, scrollBar }) => {
  // 以螢幕寬度 1200 為初始值，並設定其他值
  const initScreenWidth = 1200;
  const [values, setValues] = useState({
    pathDashOff: 830,
    beginLine: 1185,
    endLine: 695,
    arrow_a: 500,
    arrow_b: 485,
    arrow_c: 470,
  });


  const svgRef = useRef(null);
  const pathRef = useRef(null);
  const arrowRef = useRef(null);

  // 設定圖畫某些值的位置與長度(隨著螢幕等比縮放)
  useEffect(() => {
    // 每次改變螢幕寬度時，初始值並重新計算
    if (scrollBar === 0) {
      setValues({
        pathDashOff: 830,
        beginLine: 1200,
        endLine: 705,
        arrow_a: 510,
        arrow_b: 495,
        arrow_c: 480,
      });
    } else {
      setValues({
        pathDashOff: 830,
        beginLine: 1185,
        endLine: 695,
        arrow_a: 500,
        arrow_b: 485,
        arrow_c: 470,
      });
    }

    if (curentScreenWidth < initScreenWidth) {
      const value = (initScreenWidth - curentScreenWidth) * 0.5

      setValues(prevValues => ({
        pathDashOff: prevValues.pathDashOff - value,
        beginLine: prevValues.beginLine - value * 2,
        endLine: prevValues.endLine - value,
        arrow_a: prevValues.arrow_a - value,
        arrow_b: prevValues.arrow_b - value,
        arrow_c: prevValues.arrow_c - value,
      }));
    } else if (curentScreenWidth > initScreenWidth) {
      const value = (curentScreenWidth - initScreenWidth) * 0.5

      setValues(prevValues => ({
        pathDashOff: prevValues.pathDashOff + value,
        beginLine: prevValues.beginLine + value * 2,
        endLine: prevValues.endLine + value,
        arrow_a: prevValues.arrow_a + value,
        arrow_b: prevValues.arrow_b + value,
        arrow_c: prevValues.arrow_c + value,
      }));
    }
  }, [curentScreenWidth, scrollBar])

  // 動態描繪 Egg 動畫 (綠色部分)
  useEffect(() => {
    if (animationStart === 1) {
      let pathDashOffset = values.pathDashOff;
      let arrowDashOffset = 100;

      // 重置箭頭路徑的狀態
      arrowRef.current.setAttribute('stroke-dashoffset', arrowDashOffset);
      arrowRef.current.style.opacity = '0';

      const animationInterval = setInterval(() => {
        pathDashOffset -= 10;
        if (pathDashOffset <= 0) {
          clearInterval(animationInterval);
          pathRef.current.setAttribute('stroke-dashoffset', 0);
          setTimeout(() => {
            arrowRef.current.style.opacity = '1';
            const arrowAnimation = setInterval(() => {
              arrowDashOffset -= 5;
              if (arrowDashOffset <= 0) {
                clearInterval(arrowAnimation);
              }
              arrowRef.current.setAttribute('stroke-dashoffset', arrowDashOffset);
            }, 20);
          }, 50);
        } else {
          pathRef.current.setAttribute('stroke-dashoffset', pathDashOffset);
        }
      }, 30);

      return () => {
        clearInterval(animationInterval);
      };
    }
  }, [animationStart, curentScreenWidth]);

  return (
    <svg className={styles.greenLine} ref={svgRef} width="100%" height="230" >
      <path
        ref={pathRef}
        d={`M ${values.beginLine} 100 L ${values.endLine} 100 a 102 102 0 1 1 -210 0`}
        stroke="#71E3D3"
        strokeWidth="4"
        fill="none"
        strokeDasharray={values.pathDashOff}
        strokeDashoffset={values.pathDashOff}
      />
      <path
        ref={arrowRef}
        d={`M${values.arrow_a},103 L${values.arrow_b},93 L${values.arrow_c},103`}
        stroke="#71E3D3"
        fill="none"
        strokeWidth="3"
        strokeDasharray="100"
        strokeDashoffset="100"
        style={{ opacity: 0 }}
      />
    </svg>

  );
};

const BlueLineAnimation = ({ animationStart, curentScreenWidth, scrollBar }) => {
  // 以螢幕寬度 1200 為初始值，並設定其他值
  const initScreenWidth = 1200;
  const [values, setValues] = useState({
    pathDashOff: 820,
    lineLength: 485,
    arrow_a: 710,
    arrow_b: 695,
    arrow_c: 680,
  });

  const svgRef = useRef(null);
  const pathRef = useRef(null);
  const arrowRef = useRef(null);

  // 設定圖畫某些值的位置與長度(隨著螢幕等比縮放)
  useEffect(() => {
    if (scrollBar === 0) {
      setValues({
        pathDashOff: 840,
        lineLength: 495,
        arrow_a: 720,
        arrow_b: 705,
        arrow_c: 690,
      });
    } else {
      setValues({
        pathDashOff: 820,
        lineLength: 485,
        arrow_a: 710,
        arrow_b: 695,
        arrow_c: 680,
      });
    }

    if (curentScreenWidth < initScreenWidth) {
      const value = (initScreenWidth - curentScreenWidth) * 0.5

      setValues(prevValues => ({
        pathDashOff: prevValues.pathDashOff - value,
        lineLength: prevValues.lineLength - value,
        arrow_a: prevValues.arrow_a - value,
        arrow_b: prevValues.arrow_b - value,
        arrow_c: prevValues.arrow_c - value,
      }));
    } else if (curentScreenWidth > initScreenWidth) {
      const value = (curentScreenWidth - initScreenWidth) * 0.5

      setValues(prevValues => ({
        pathDashOff: prevValues.pathDashOff + value,
        lineLength: prevValues.lineLength + value,
        arrow_a: prevValues.arrow_a + value,
        arrow_b: prevValues.arrow_b + value,
        arrow_c: prevValues.arrow_c + value,
      }));
    }
  }, [curentScreenWidth, scrollBar])

  // 動態描繪 Egg 動畫 (藍色部分)
  useEffect(() => {
    if (animationStart === 1) {

      let pathDashOffset = values.pathDashOff;
      let arrowDashOffset = 100;

      // 重置箭頭路徑的狀態
      arrowRef.current.setAttribute('stroke-dashoffset', arrowDashOffset);
      arrowRef.current.style.opacity = '0';

      const animationInterval = setInterval(() => {
        pathDashOffset -= 10;
        if (pathDashOffset <= 0) {
          clearInterval(animationInterval);
          pathRef.current.setAttribute('stroke-dashoffset', 0);
          setTimeout(() => {
            arrowRef.current.style.opacity = '1';
            const arrowAnimation = setInterval(() => {
              arrowDashOffset -= 5;
              if (arrowDashOffset <= 0) {
                clearInterval(arrowAnimation);
              }
              arrowRef.current.setAttribute('stroke-dashoffset', arrowDashOffset);
            }, 20);
          }, 50);
        } else {
          pathRef.current.setAttribute('stroke-dashoffset', pathDashOffset);
        }
      }, 30);

      return () => {
        clearInterval(animationInterval);
      };
    }

  }, [animationStart, curentScreenWidth]);

  return (
    <svg className={styles.blueLine} ref={svgRef} width="100%" height="230" >
      <path
        ref={pathRef}
        d={`M 0 150 L ${values.lineLength} 150 a 102 102 0 1 1 210 0`}
        stroke="#51B2F9"
        strokeWidth="4"
        fill="none"
        strokeDasharray={values.pathDashOff}
        strokeDashoffset={values.pathDashOff}
      />
      <path
        ref={arrowRef}
        d={`M${values.arrow_a},148 L${values.arrow_b},158 L${values.arrow_c},148`}
        stroke="#51B2F9"
        fill="none"
        strokeWidth="3"
        strokeDasharray="100"
        strokeDashoffset="100"
        style={{ opacity: 0 }}
      />
    </svg>

  );
};

// 分數動畫
const AnimatedNumber = ({ targetNumber, duration, fontColor, scoreAnimationCount }) => {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    if (scoreAnimationCount) {
      const increment = Math.ceil(targetNumber / (duration / 10));
      const timer = setInterval(() => {
        setCurrentNumber((prevNumber) => {
          const newNumber = prevNumber + increment;
          if (newNumber >= targetNumber) {
            clearInterval(timer);
            return targetNumber;
          }
          return newNumber;
        });
      }, 40);

      return () => {
        clearInterval(timer);
      };
    }
  }, [scoreAnimationCount]);

  return <h2 className={styles.indicatorScore} style={{ color: fontColor }}>+{currentNumber}%</h2>;
};

const ContentLayer = ({ scrollBar }) => {
  const navigate = useNavigate();
  const scoreAnimationRef = useRef(null);
  const lineAnimationRef = useRef(null);
  const [curentScreenWidth, setCurentScreenWidth] = useState(document.body.clientWidth);

  // 動畫次數
  const [eggAnimationCount, setEggAnimationCount] = useState(0);
  const [scoreAnimationCount, setScoreAnimationCount] = useState(0);

  // 動畫是否已經執行過
  const [scoreAnimationIsExecuted, setScoreAnimationIsExecuted] = useState(false);
  const [lineAnimationIsExecuted, setLineAnimationIsExecuted] = useState(false);

  // 觀察是否接觸到指定元素，如有並且接觸次數為零，執行動畫。
  useIntersectionObserver(eggAnimationCount, lineAnimationRef, setLineAnimationIsExecuted);
  useIntersectionObserver(scoreAnimationCount, scoreAnimationRef, setScoreAnimationIsExecuted);

  const handleLoginBtn = () => {
    window.scrollTo(0, 0);
    navigate("/toeic");
    // if(process.env.REACT_APP_ENV_NAME === "dev"){
    //   window.location.href = 'https://testprep-dev.tageasy.com.tw/auth/login'
    // }else{
    //   window.location.href = 'https://testprep.tageasy.com.tw/auth/login'
    // }
  }

  // 計算當前使用者所在位置寬度
  useEffect(() => {
    const handleResize = () => {
      setCurentScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 動畫次數 +1 (設定只執行一次)。
  useEffect(() => {

    if (scoreAnimationCount < 1 && scoreAnimationIsExecuted) {
      setScoreAnimationCount(p => p + 1);
    }

    if (eggAnimationCount < 1 && lineAnimationIsExecuted) {
      setEggAnimationCount(p => p + 1);
    }

  }, [scoreAnimationIsExecuted, lineAnimationIsExecuted])

  return (
    <div className={styles.container}>
      <div className={styles.wrap}>
        <Box>
          <Grid container className={styles.agilityContentBox}>
            {curentScreenWidth > 900 ?
              <>
                <Grid item md={6} sm={12} className={styles.agilityLeftContent}>
                  <div>
                    <h2>敏捷學習</h2>
                    <h1>用 AI 激發更多的學習力</h1>
                    <div><span>結合獨門的AI演算技術，系統會在每一個學習週期 (Sprint)，依照使用者先前的表現，快速反應並生成新的學習計畫，讓使用者可以學得更有效率。</span></div>
                    <Button className={styles.agilityBtn} onClick={() => handleLoginBtn()}>馬上激發您的學習潛能</Button>
                  </div>
                </Grid>
                <Grid item md={6} sm={12}>
                  <img src={to_do_list} className={styles.img_to_do_list} />
                </Grid>
              </>
              :
              <>
                <Grid item md={6} sm={12} className={styles.agilityLeftContent}>
                  <div>
                    <h2>敏捷學習</h2>
                    <h1>用 AI 激發更多的學習力</h1>
                    <div><span>結合獨門的AI演算技術，系統會在每一個學習週期 (Sprint)，依照使用者先前的表現，快速反應並生成新的學習計畫，讓使用者可以學得更有效率。</span></div>
                    <Button className={styles.agilityBtn} onClick={() => handleLoginBtn()}>馬上激發您的學習潛能</Button>
                  </div>
                </Grid>
                <Grid item md={6} sm={12} style={{ padding: "0 5%" }}>
                  <img src={to_do_list} className={styles.img_to_do_list} />
                </Grid>
              </>
            }
          </Grid>
        </Box>
        <Box className={styles.pushQuestionBox}>
          <Grid container className={styles.pushQuestionContentBox}>
            {curentScreenWidth > 900 ?
              <>
                <Grid item md={6} sm={12}><img src={smart_push} /></Grid>
                <Grid item md={6} sm={12} className={styles.pushQuestionRightContent}>
                  <div>
                    <h2>智慧推題</h2>
                    <h1>自適應的個人化題庫</h1>
                    <span>系統會根據使用者的表現，推送當下最需要練習的題目，讓使用者不再需要盲目做題，有效提升解題效率，強化學習表現，深化記憶效果。</span>
                  </div>
                </Grid>
              </> :
              <>
                <Grid item md={6} sm={12} className={styles.pushQuestionRightContent}>
                  <div>
                    <h2>智慧推題</h2>
                    <h1>自適應的個人化題庫</h1>
                    <span>系統會根據使用者的表現，推送當下最需要練習的題目，讓使用者不再需要盲目做題，有效提升解題效率，強化學習表現，深化記憶效果。</span>
                  </div>
                </Grid>
                <Grid item md={6} sm={12} style={{ padding: "0 5%" }}><img src={smart_push} /></Grid>
              </>
            }
          </Grid>
        </Box>
        <Box className={styles.indicatorBox}>
          <Grid><h1>雙效 S 指標</h1></Grid>
          <Grid><span>持續固定的練習，經過12週後，平均答題數達到1500題，不論是分數或是答題時間，都可以獲得更顯著的進步。</span></Grid>
          <Grid container className={styles.indicatorContentBox}>

            <Grid item xs={6} className={styles.indicatorLeftContent}>
              <div ref={scoreAnimationRef}>
                <h1>Score</h1>
                <AnimatedNumber targetNumber={23} duration={2000} fontColor={"#55B8F5"} scoreAnimationCount={scoreAnimationCount} />
              </div>
            </Grid>

            <Grid item xs={6} className={styles.indicatorRightContent}>
              <div ref={scoreAnimationRef}>
                <h1>Speed</h1>
                <AnimatedNumber targetNumber={9} duration={2000} fontColor={"#6EDDD9"} scoreAnimationCount={scoreAnimationCount} />
              </div>
            </Grid>

          </Grid>
        </Box>
        <Box className={styles.cycleBox}>
          <Grid style={{ padding: "0 5%" }}>
            <h1>堅實的學習循環</h1>
            <span >精準前測、學習計畫、預測分數、診斷報告，四效合一的學習循環，實現有效率又有價值的長遠目標。</span>
          </Grid>
          {curentScreenWidth > 900 ?
            <>
              <Grid container className={styles.cycleContentBox}>
                <Grid item xs={5} className={styles.cycleLeftContent}>
                  <div>
                    <h1 style={{ color: "#57BBF2" }}>精準前測</h1>
                    <span>系統依據前測表現與設定的學習目標，分析落點並產生個人化的學習計畫。</span>

                  </div>
                </Grid>
                <Grid item xs={2} className={styles.cycleCenterContent}>
                  <div>
                    <span style={{ color: "#57BBF2" }}>學習計畫</span>
                    <h1>GOAL</h1>
                    <span style={{ color: "#6AD7DD" }}>診斷報告</span>
                  </div>
                </Grid>
                <Grid item xs={5} className={styles.cycleRightContent}>
                  <div>
                    <h1 style={{ color: "#6AD7DD" }}>預測落點分數</h1>
                    <span>學習計畫結束後，提供結果報表及正式考試預測分數，讓使用者了解自身的狀況。</span>
                  </div>
                </Grid>
                <div ref={lineAnimationRef}>
                  <BlueLineAnimation animationStart={eggAnimationCount} curentScreenWidth={curentScreenWidth} scrollBar={scrollBar} />
                  <GreenLineAnimation animationStart={eggAnimationCount} curentScreenWidth={curentScreenWidth} scrollBar={scrollBar} />
                </div>
              </Grid>
            </> :
            <>
              <Grid container className={styles.cycleContentBox} >
                <Grid item xs={12} className={styles.cycleLeftContent}>
                  <div>
                    <h1 style={{ color: "#57BBF2" }}>精準前測</h1>
                    <span>系統依據前測表現與設定的學習目標，分析落點並產生個人化的學習計畫。</span>
                  </div>
                </Grid>
                <Grid item xs={12} className={styles.cycleCenterContent}>
                  <div>
                    <span style={{ color: "#57BBF2" }}>學習計畫</span>
                    <h1>GOAL</h1>
                    <span style={{ color: "#6AD7DD" }}>診斷報告</span>
                  </div>
                  <div ref={lineAnimationRef}>
                    <BlueLineAnimation animationStart={eggAnimationCount} curentScreenWidth={curentScreenWidth} scrollBar={scrollBar} />
                    <GreenLineAnimation animationStart={eggAnimationCount} curentScreenWidth={curentScreenWidth} scrollBar={scrollBar} />
                  </div>
                </Grid>
                <Grid item xs={12} className={styles.cycleRightContent}>
                  <div>
                    <h1 style={{ color: "#6AD7DD" }}>預測落點分數</h1>
                    <span style={{ textAlign: "center" }}>學習計畫結束後，提供結果報表及正式考試預測分數，讓使用者了解自身的狀況。</span>
                  </div>
                </Grid>

              </Grid>
            </>
          }
          <Grid><Button className={styles.cycleBtn} onClick={() => handleLoginBtn()}>現在就學得更快更好</Button></Grid>
        </Box>
      </div>
    </div>
  )
}

export default ContentLayer;






