import { useEffect, useState } from 'react'
import {
    Grid,
} from "@mui/material"
import styles from './index.module.scss';
import TitleLayer from '../TitleLayer';
import AboutLayer from '../AboutLayer';
import ContentLayer from '../ContentLayer';
import FootLayer from '../FootLayer';
import BannerLayer from '../BannerLayer';


function HomePage() {
    // 瀏覽器 scrollBar 的寬度
    const [scrollBar, setScrollBar] = useState();

    useEffect(()=>{
      setScrollBar(window.innerWidth - document.body.clientWidth);
    },[])

  return (
    <>
        <BannerLayer url={"Home"}/>
        <TitleLayer/>
        <AboutLayer/>
        <ContentLayer scrollBar={scrollBar}/>
        <FootLayer/>
    </>
  )
}

export default HomePage;
